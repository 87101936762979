import React from 'react';



export function MarkdownContent({content, component: Component = 'div', ...otherProps}) {
    const arrContent = Array.isArray(content) ? content : [content];

    const finalContent = arrContent.filter(x => x)
        .map(x => typeof x === 'string' ? x : x.childMarkdownRemark?.html)
        .join('');

    return <Component dangerouslySetInnerHTML={{__html: finalContent}} {...otherProps} />;
}
