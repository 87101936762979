import React from 'react';
import classNames from 'classnames';

const styles = require('./TypographyV2.module.scss');

/**
 *
 * @param Tag
 * @param isBold
 * @param className
 * @param isDocumentTypography {boolean}
 * @param color {"light" | "primary-headers"}
 * @param variant {"body" | "body-l" | "lead"}
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function TypographyV2({
                                 tag: Tag = 'p',
                                 isBold,
                                 className,
                                 isDocumentTypography,
                                 color,
                                 variant = 'body',
                                 ...props
                             }) {
    return <Tag data-variant={variant}
                data-color={color}
                data-is-bold={isBold}
                data-document-typography={isDocumentTypography}
                className={classNames(className, styles.root)}{...props} />;
}
