import * as styles from './BlogPostMediaObject.module.scss';
import React from 'react';
import classNames from 'classnames';
import {componentRef} from "../../functions/componentRef";
import {Link} from 'gatsby';
import {Typography} from "../Typography";
import {linkToLangVersion} from "../../functions/linkToLangVersion";
import {SVGSupportImg} from "../SVGSupportImg";

export function BlogPostMediaObject({
                                        title,
                                        slug,
                                        className,
                                        description,
                                        language,
                                        category,
                                        author,
                                        image,
                                        index,
                                        smallImage,
                                        appearance = 'default',
                                        ...otherProps
                                    }) {
    const link = linkToLangVersion(language, `/blog/${slug}`);
    const classes = classNames(className, styles.root, styles[`appearance_${appearance}`]);
    const finalImage = appearance === 'hero' ? (index === 0 ? image : smallImage) : image;
    return <article className={classes} {...otherProps} {...componentRef('blog-post')}>
        <Link to={link} className={styles.link}>
            <div className={styles.wrapper}>
                <SVGSupportImg {...finalImage} alt={title} applyPresentationSize={false} className={styles.image}/>
                <div className={styles.content}>
                    <h1 className={styles.title}>{title}</h1>
                    <span className={styles.category}>{category}</span>
                    <span className={styles.author}>{author}</span>
                    <Typography className={styles.description}>
                        {description}
                    </Typography>
                </div>
            </div>
        </Link>
    </article>;

}
