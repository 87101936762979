// extracted by mini-css-extract-plugin
export var appearance_default = "BlogPostMediaObject-module--appearance_default--fba73";
export var appearance_hero = "BlogPostMediaObject-module--appearance_hero--a33fa";
export var appearance_small = "BlogPostMediaObject-module--appearance_small--abb71";
export var author = "BlogPostMediaObject-module--author--19a32";
export var category = "BlogPostMediaObject-module--category--0d524";
export var content = "BlogPostMediaObject-module--content--7392e";
export var description = "BlogPostMediaObject-module--description--69980";
export var image = "BlogPostMediaObject-module--image--bf38b";
export var link = "BlogPostMediaObject-module--link--443b9";
export var root = "BlogPostMediaObject-module--root--5b04b";
export var title = "BlogPostMediaObject-module--title--57340";
export var wrapper = "BlogPostMediaObject-module--wrapper--27508";