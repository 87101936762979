import React from 'react';
import classNames from 'classnames';
import * as styles from './BlockTitle.module.scss';
import {componentRef} from "../../functions/componentRef";
import {TypographyV2} from "../TypographyV2";

export function BlockTitle({className, tag = "h1", titleVariant, color, noTopMargin = false, children, ...otherProps}) {
    return <TypographyV2 variant={titleVariant ?? tag} tag={tag} data-color={color}
                         className={classNames(className, styles.root, noTopMargin && styles.noTopMargin)} {...otherProps} {...componentRef('block-title')}>
        {children}
    </TypographyV2>;
}
