import React from 'react';
import classNames from 'classnames';
import * as styles from './ArticlesListHero.module.scss';
import {Card} from "../Card";
import {componentRef} from "../../functions/componentRef";

export function ArticlesListHero({className, children, ...otherProps}) {
    return <Card className={classNames(className, styles.root)} {...componentRef('articles-list-hero')} {...otherProps}>
        {React.Children.map(children, (child) => {
            return React.cloneElement(child, {
                ...child.props,
                ...componentRef.subcomponent('article'),
                appearance: 'hero',
                className: classNames(child.props.className, styles.article)
            })
        })}
    </Card>
}
