import React from 'react';
import * as styles from './ArticlesListWithFilter.module.scss';
import classNames from 'classnames';
// import {InlineSingleOption} from "../InlineSingleOption";
import {Button} from "../Button";
import {LangLink} from "../LangLink";

// const OPTIONS = {
//     all: 'All',
//     digital_transformation: 'Digital transformation',
//     process: 'Process',
//     people: 'People',
//     others: 'Others'
// };

export function ArticlesListWithFilter({children, className, nextLink, previousLink, ...otherProps}) {
    return <section className={classNames(className, styles.root)}>
        {/*<InlineSingleOption*/}
        {/*    className={styles.filter}*/}
        {/*    options={OPTIONS}*/}
        {/*    value={category || 'all'}*/}
        {/*    onChange={onCategoryChange}/>*/}

        {previousLink ? <Button component={LangLink}
                                to={previousLink}
                                className={styles.previousPageButton}>Previous page</Button>:undefined}
        <div className={styles.content}>
            {React.Children.map(children, x => {
                return React.cloneElement(x, {
                    ...x.props,
                    className: classNames(x.props.className, styles.article)
                })
            })}
        </div>
        {nextLink ? <Button component={LangLink}
                            to={nextLink}
                            className={styles.nextPageButton}>Next page</Button>:undefined}
    </section>
}
