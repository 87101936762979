import {Layout} from "../components/Layout";
import React from 'react';
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {Breadcrumbs} from "../components/Breadcrumbs";
import {BlockTitle} from "../components/BlockTitle";
import {ArticlesListHero} from "../components/ArticlesListHero";
import {BlogPostMediaObject} from "../components/BlogPostMediaObject";
import {ContactPersonBlock} from "../components/ContactPersonBlock";
import {ArticlesListWithFilter} from "../components/ArticlesListWithFilter";
import {graphql} from "gatsby";
import {MarkdownContent} from "../components/MarkdownContent";
import * as styles from './blog.module.scss';
import {useIntl} from 'gatsby-plugin-react-intl';
import {createSeoData} from "../functions/createSeoData";


export const query = graphql`
    query Blog($locale: String!, $lang: String!) {
        posts: allStrapiBlogPost(
            filter: {language: {eq: $lang}, slug: {nin: ["60-oszczednosci-czasu-na-obsludze-faktur"]}}
            sort: {fields: publishDate, order: DESC}
            limit: 6
        ) {
            edges {
                node {
                    title
                    slug
                    isFeatured
                    category
                    createdAt
                    language
                    author {
                        name
                    }
                    intro {
                        data {
                            childMarkdownRemark {
                                html
                            }
                        }
                    }
                    image {
                        localFile {
                            url
                            childImageSharp {
                                gatsbyImageData(width: 650, height: 530, placeholder: NONE, layout: FIXED)
                            }
                        }
                    }
                }
            }
        }
        featuredPosts: allStrapiBlogPost(
            filter: {language: {eq: $lang}, isFeatured: {eq: true}}
            sort: {fields: publishDate, order: DESC}
            limit: 4
        ) {
            edges {
                node {
                    title
                    slug
                    isFeatured
                    category
                    createdAt
                    language
                    author {
                        name
                    }
                    intro {
                        data {
                            childMarkdownRemark {
                                html
                            }
                        }
                    }
                    image {
                        localFile {
                            url
                            childImageSharp {
                                gatsbyImageData(width: 630, height: 530, placeholder: NONE, layout: FIXED)
                            }
                        }
                    },
                    smallImage: image {
                        localFile {
                            url
                            childImageSharp {
                                gatsbyImageData(width: 360, height: 200, placeholder: NONE, layout: FIXED)
                            }
                        }
                    }
                }
            }
        }
        page: strapiBlogPage(locale: {eq: $locale}) {
            title
            contactPersonTitle
            contactPersonContent {
                data {
                    childMarkdownRemark {
                        html
                    }    
                }
            }
            contactPerson {
                name
                position
                email
                phone
                bigImage {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 490
                                height: 900
                                placeholder: NONE
                                transformOptions: {cropFocus: NORTH}
                                layout: FIXED
                            )
                        }
                    }
                }
                mobileBigImage: bigImage {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 300
                                height: 400
                                placeholder: NONE
                                transformOptions: {cropFocus: NORTH}
                                layout: FIXED
                            )
                        }
                    }
                }
            }
            seo {
                ...Seo
            }
        }
    }
`;

export default function BlogIndexPage({data, pageContext}) {
    const intl = useIntl();

    const articles = data.posts.edges;
    const heroArticles = data.featuredPosts.edges;

    return (
        <Layout services={pageContext.services}
                oldServices={pageContext.oldServices}
                linkableSubServices={pageContext.linkableSubServices}
                jobsCount={pageContext.jobsCount}
                seo={createSeoData(data.page.seo)}
                title={intl.formatMessage({id: 'menu.blog'})}
                defaultHrefLang={pageContext.lang}
                lang={pageContext.lang}
                companyData={pageContext.companyData}
                hrefLangs={[pageContext.lang]}>
            <PageMainColumnWrapper>
                <Breadcrumbs className={styles.breadcrumbs}>
                    <Breadcrumbs.Item to="/">{intl.formatMessage({id: 'menu.home'})}</Breadcrumbs.Item>
                    <Breadcrumbs.Item>{data.page.title}</Breadcrumbs.Item>
                </Breadcrumbs>

                <BlockTitle className={styles.title}>{data.page.title}</BlockTitle>
                <ArticlesListHero>
                    {heroArticles.map((x, index) => {
                        return <BlogPostMediaObject key={index}
                                                    title={x.node.title}
                                                    slug={x.node.slug}
                                                    language={x.node.language}
                                                    category={x.node.category}
                                                    description={<MarkdownContent content={x.node.intro.data}/>}
                                                    author={x.node.author.name}
                                                    image={x.node.image.localFile}
                                                    index={index}
                                                    smallImage={x.node.smallImage?.localFile}
                        />
                    })}
                </ArticlesListHero>
                <ArticlesListWithFilter nextLink={pageContext.pages > 1 ? '/blog/page-2' : undefined}>
                    {articles.map((x, index) => {
                        return <BlogPostMediaObject
                            title={x.node.title}
                            key={index}
                            slug={x.node.slug}
                            category={x.node.category}
                            language={x.node.language}
                            index={index}
                            author={x.node.author.name}
                            description={<MarkdownContent content={x.node.intro.data}/>}
                            image={x.node.image.localFile}
                        />
                    })}
                </ArticlesListWithFilter>
            </PageMainColumnWrapper>
            <ContactPersonBlock
                title={data.page.contactPersonTitle}
                image={data.page.contactPerson.bigImage.localFile}
                mobileImage={data.page.contactPerson.mobileBigImage.localFile}
                person={{
                    phone: data.page.contactPerson.phone,
                    email: data.page.contactPerson.email,
                    name: data.page.contactPerson.name,
                    position: data.page.contactPerson.position
                }}
            >
                <MarkdownContent content={data.page.contactPersonContent}/>
            </ContactPersonBlock>

        </Layout>
    );
}
